.contact-section {
	position: relative;
	padding: 20px 0px 150px;
	overflow: hidden;
	.pattern-layer{
		position: absolute;
		left: 0px;
		bottom: 80px;
		width: 774px;
		height: 416px;
		background-repeat: no-repeat;
	}
	.pattern-layer-two{
		position: absolute;
		right: 0px;
		top: 180px;
		width: 454px;
		height: 120px;
		background-repeat: no-repeat;
	}
	.icon-layer-two{
		position: absolute;
		left: 100px;
		top: 250px;
		width: 69px;
		height: 69px;
		opacity: 0.6;
		background-repeat: no-repeat;
	}
	.icon-layer-three{
		position: absolute;
		right: 230px;
		bottom: 230px;
		width: 69px;
		height: 69px;
		background-repeat: no-repeat;
	}
	.info-column{
		position: relative;
		margin-bottom: 40px;
		.inner-column {
			position: relative;
			padding-top: 0px;
		}
		.icon-layer{
			position: absolute;
			left: -70px;
			top: 0px;
			width: 160px;
			height: 133px;
			background-repeat: no-repeat;
		}
		.image{
			position: relative;
			margin-bottom: 35px;
		}
		h5{
			position: relative;
			color: #06092d;
			font-weight: 700;
			line-height: 1.3em;
			margin-bottom: 2rem;
		}
		.contact-list{
			i {
				color: #86bc24;
				font-size: 18px;
				margin-right: 10px;
				width: 35px !important;
				height: 40px;
				border-radius: 100px;
				background: #ffffff;
				display: inline-block;
				text-align: center;
				line-height: 40px;
				max-width: 40px !important;
				flex: 0 0 40px;
				box-shadow: 0px 0px 20px #a1a1a159;
			}
			span {
				position: relative;
				color: #06092d;
				font-weight: 400;
				font-size: 16px;
				line-height: 1.7em;
			}
			position: relative;
			li {
				position: relative;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				a {
					position: relative;
					color: #06092d;
					font-size: 16px;
					transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-webkit-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					&:hover {
						color: #111111;
					}
				}
			}
		}
	}
	.form-column.form_z {
		z-index: 1;
	}
	.form-column {
		position: relative;
		margin-bottom: 40px;
		.inner-column {
			position: relative;
			padding: 60px 50px;
			margin-left: 40px;
			background-color: #ffffff;
			box-shadow: 0px 0px 20px rgba(0,0,0,0.15);
		}
		
	}
	.color-layer {
		position: absolute;
		right: -100px;
		bottom: -100px;
		width: 465px;
		height: 465px;
		z-index: -1;
		border-radius: 50%;
		background-color: #86bc24;
		opacity: 0.1;
	}
}
.default-form {
	.form-group {
		position: relative;
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0px;
		}
		input[type="text"] {
			position: relative;
			display: block;
			width: 100%;
			line-height: 24px;
			padding: 15px 20px;
			color: #777777;
			height: 50px;
			font-size: 14px;
			background: none;
			font-weight: 400;
			border-radius: 4px;
			border: 1px solid #efefef;
			-webkit-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		input[type="email"] {
			position: relative;
			display: block;
			width: 100%;
			line-height: 24px;
			padding: 15px 20px;
			color: #777777;
			height: 50px;
			font-size: 14px;
			background: none;
			font-weight: 400;
			border-radius: 4px;
			border: 1px solid #efefef;
			-webkit-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		select {
			position: relative;
			display: block;
			width: 100%;
			line-height: 24px;
			padding: 15px 20px;
			color: #777777;
			height: 50px;
			font-size: 14px;
			background: none;
			font-weight: 400;
			border-radius: 4px;
			border: 1px solid #efefef;
			-webkit-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		input {
			&::-webkit-input-placeholder {
				color: #777777;
			}
		}
		textarea {
			&::-webkit-input-placeholder {
				color: #777777;
			}
			position: relative;
			display: block;
			width: 100%;
			line-height: 24px;
			padding: 15px 25px;
			color: #777777;
			height: 140px;
			border-radius: 4px;
			border: 1px solid #efefef;
			resize: none;
			font-size: 14px;
			font-weight: 400;
			-webkit-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		.theme-btn {
			cursor: pointer;
		}
	}
}

